import React from 'react'
import { Link } from 'gatsby'
import { Layout } from '../components'

const Success = () => {
  return (
    <Layout>
      <section className="success-page">
        <div className="page-center">
          <h2>Your submission was recieved ! </h2>
          <Link to="/" className="btn">
            홈으로 돌아가기
          </Link>
        </div>
      </section>
    </Layout>
  )
}

export default Success
